<template>
  <router-view/>
</template>




<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Noto+Serif&family=Roboto&display=swap');
@import './styles/variables.css';
#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-texto-principal);
  a {
    color: inherit;
    text-decoration: inherit;
  }
}
</style>
