import { createStore } from 'vuex'

export default createStore({
  state: {
    variables:{
      version: '0.3.1',
      encasillado: true,
      menu_encasillado: true,
      modo_oscuro: false,
      //url_host: 'http://localhost:3001',
      url_host: 'https://api.failder.com',
      //url_host: 'http://192.168.8.202:3001',
      variables_test:{

      }
    },
  },
  mutations: {
    set_variables(state, variables){
      state.variables = variables;
    },
  },
  actions: {
    setVariables({commit, state, dispatch}, variables) {
      commit('set_variables', variables);
    }
  },
  modules: {
  }
})
