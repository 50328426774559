<template>
  <!-- Menu PC -->
  <div class="viewPC menu_web">
    <div class="div_contenedor_contenido background_menu" v-bind:class="{ background_menu_especial: datos.scroll_position > 40}">
        <div class="viewPC contenedor_menu" v-bind:class="{ contenedor_menu_mini: datos.scroll_position > 40, encasillado: datos.menu_encasillado }">
          <div class="logo_pc">
            <img src="../assets/logos/LOGO_Blue-Black.png" class="logo" alt="Failder">
          </div>
          <div class="botonera_pc">
            <a href="https://app.failder.com/">
              <div class="btn_login">
                Login
              </div>
            </a>
            <a href="https://app.failder.com/registro/">
              <div class="btn_registro">
                Regístrate gratis
              </div>
            </a>
          </div>
        </div>
    </div>
  </div>

  <!-- //Menu PC -->

  <!-- Menu MOVIL -->
  <div class="viewMovil menu_movil">
    <div class="div_contenedor_contenido background_menu" v-bind:class="{ background_menu_especial: (datos.scroll_position > 40 || datos.mostrar_menu_movil)}">
      <div class="contenedor_menu" v-bind:class="{ contenedor_menu_mini: datos.scroll_position > 40 }">
        <div class="logo_movil">
          <img src="../assets/logos/LOGO_Blue-Black.png" class="logo" alt="Failder">
        </div>
        <div class="div_menu_movil" @click.native="mostrar_ocultar_menu()">
          <img src="../assets/iconos/lista.svg" class="btn_menu" alt="Menu">
        </div>
        <!--<div class="botonera_pc">
          <a href="https://app.failder.com/">
            <div class="btn_login">
              Login
            </div>
          </a>
          <a href="https://app.failder.com/registro/">
            <div class="btn_registro">
              Registrate gratis
            </div>
          </a>
        </div>-->
      </div>
    </div>
    <div class="contenido_menu_movil" v-show="datos.mostrar_menu_movil">
      <div class="botonera_movil">
        <a href="https://app.failder.com/">
          <div class="btn_login">
            Login
          </div>
        </a>
        <a href="https://app.failder.com/registro/">
          <div class="btn_registro">
            Regístrate gratis
          </div>
        </a>
      </div>
    </div>
    <div class="bloqueador_menu_movil" v-bind:class="{ bloqueador_menu_movil_2: datos.mostrar_menu_movil}"></div>
  </div>
  <!-- //Menu Movil -->
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import { VueReCaptcha } from 'vue-recaptcha-v3'


export default {
  name: 'HeaderNavigation',
  setup(){
    const store = useStore();
    const route = useRoute();
    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    datos.scroll_position = 0;

    datos.mostrar_menu_movil = false;
    datos.bajando = false;
    datos.token_recaptcha = '';


    onMounted(function (){
      console.log('Comprobar URL', route.fullPath)
      /*if (datos.modo_oscuro){
        document.body.classList.add("fondo_dark_theme");
        document.getElementById('app').classList.add("texto_dark_theme");
      } else {
        document.body.classList.remove("fondo_dark_theme")
        document.getElementById('app').classList.remove("texto_dark_theme");
      }*/
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      // Do stuff with the received token.
      //console.log('token', token);
      this.datos.token_recaptcha = token;


    },
    //Funciones
    handleScroll (event) {
      let html = document.documentElement;

      /*var height = Math.max( body.scrollHeight, body.offsetHeight,
          html.clientHeight, html.scrollHeight, html.offsetHeight );*/
      /*var height = Math.max( document.documentElement.body.scrollHeight, document.documentElement.body.offsetHeight,
          document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
      console.log(height)*/
      //console.log( html.scrollHeight-html.clientHeight)

      if (( html.scrollHeight-html.clientHeight)-document.documentElement.scrollTop < 200){
        this.datos.aviso_lega_fijo = false;
      }

      if (( html.scrollHeight-html.clientHeight)-document.documentElement.scrollTop > 500 && (!this.datos.aviso_lega_fijo)){
        this.datos.aviso_lega_fijo = true;
      }

      //console.log(document.documentElement.scrollTop)
      // Any code to be executed when the window is scrolled
      if (document.documentElement.scrollTop > this.datos.scroll_position){
        this.datos.bajando = true;
      } else {
        this.datos.bajando = false;
      }
      this.datos.scroll_position = document.documentElement.scrollTop
    },
    mostrar_ocultar_menu(){
      this.datos.mostrar_menu_movil = !this.datos.mostrar_menu_movil;
    }
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    this.recaptcha();
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.div_contenedor_contenido{
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-around;
  align-items: flex-start;
}
.background_menu{
  transition: all 0.3s ease-in-out !important;
}
.menu_web{
  position: fixed;
  width: 100%;
  z-index: 200;
}
.menu_movil{
  position: fixed;
  width: 100%;
  z-index: 200;
}
.contenedor_menu{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  /*background-color: blue;*/
  transition: all 0.3s ease-in-out !important;
}
.contenedor_menu .logo_pc{
  max-width: 300px;
}
.contenedor_menu .logo_pc img{
  margin-right: 32px;
  max-width: 200px;
}
.contenedor_menu .botonera_pc{
  width: 100%;
  height: 30px;
  /*background-color: #ff0000;*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: flex-end;
  align-items: center;
}

.contenedor_menu .logo_movil{
  max-width: 300px;
}
.contenedor_menu .logo_movil img{
  margin-right: 32px;
  max-width: 200px;
}


@media screen and (min-width: 992px){
  .contenedor_menu{
    padding: 32px;
  }
  .contenedor_menu_mini{
    padding: 14px;
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 991px){
  .div_contenedor_contenido{
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.botonera_movil{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}


.background_menu_especial{
  background-color: #ffffff !important;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  margin-bottom: 10px;
}
.bloqueador_menu_movil{
  transition: all 0.3s ease-in-out !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}
.bloqueador_menu_movil_2{
  height: 100vh;
  background-color: #0000006e;
}
.contenido_menu_movil{
  background-color: #FFF;
  position: relative;
  width: 100%;
  top: -10px;
}
.div_menu_movil{
  cursor: pointer;
}
.div_menu_movil .btn_menu{
  width: 40px;
}

.nav-header img{
  width: 160px;
  transition: all 0.3s ease-in-out !important;
}
.header:hover img {
  width: 130px;
}
.header .nav-header .brand {
  display: inline-block;
  margin-top: 20px;
}

.header-hover{
  background: #fff;
  box-shadow: 0px 3px 59px 0px rgb(0 0 0 / 15%);
}

.header .menu li.active a {
  color: var(--primary-color-1);
}
.header .menu li a:hover {
  color: var(--primary-color-2);
}
@media screen and (max-width: 977px){
  .nav-header img{
    width: 100px;
  }
}
</style>
