import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import VueTypedJs from 'vue-typed-js'
import { VueReCaptcha } from 'vue-recaptcha-v3'


import './styles/custom.css';
import './styles/variables.css';
import './js/typed'

//import Typed from './js/typed'


const app = createApp(App);
app.use(VueReCaptcha, {
    siteKey: '6LfvTmQcAAAAAOWFEbO3Ix1-LuJ3fvbcIbDqBA-K',
    loaderOptions: {
        useRecaptchaNet: true
    }
}).use(store).use(router).mount('#app')
