<template>
  <header-navigation></header-navigation>
  <!--<section class="espaciador_contenido_principal" style="    background-size: cover;
    background-position: center center;" :style="{ backgroundImage: 'url(' + require('@/assets/imagenes/17973908.jpg') + ')' }">-->
  <div class="div_btn_subir_oculto" v-bind:class="{ div_btn_subir_visible: (datos.scroll_position > 40) }" @click.native="scrollTop()">
    <img src="../assets/iconos/flecha-arriba.svg" alt="arriba">
  </div>
  <section class="espaciador_contenido_principal">
    <div class="div_secction" v-bind:class="{ encasillado: datos.encasillado }">
      <div class="div_cabecera">
       <div class="items item1">
         <div class="item_texto">
           <span>Failder</span>, el servicio de monitoreo y pruebas de rendimiento
         </div>
        <div class="item_texto_cambiante">
          <span id="typed"></span>
        </div>
         <div class="item_boton">
           <div>
             <a href="https://app.failder.com/registro/">
               <div class="btn_registro">
                 Regístrate gratis
               </div>
             </a>
           </div>
         </div>
       </div>
        <div class="items ocultar_movil">
          <picture>
            <source srcset="../assets/imagenes/20945851.webp" type="image/webp">
            <!-- Imagen fallback para navegadores antiguos -->
            <img style="width: 100%;"  src="../assets/imagenes/20945851.jpg" alt="Failder.com">
          </picture>

          <!--<img style="width: 100%;" src="../assets/imagenes/20945851.webp">-->
        </div>
      </div>
    </div>
  </section>
  <section class="section_azul">
    <div class="div_secction" v-bind:class="{ encasillado: datos.encasillado }">
      <div>
        <h1>¿Qué puede hacer Failder?</h1>
      </div>
      <div>
        Saber que su sitio web está disponible y accesible para los visitantes es fundamental para todas las empresas.
      </div>
      <div class="contenido_que_hace">
        <div class="item">
          <div class="titulo">
            Monitor de tiempo de actividad
          </div>
          <div class="explicacion">
            Nuestros servidores de monitoreo estarán constantemente revisando sus sitios web y servidores para que cualquier interrupción se detecte de inmediato.
          </div>
        </div>
        <div class="item">
          <div class="titulo">
            Diagnóstico de interrupciones
          </div>
          <div class="explicacion">
            Durante una interrupción, nuestro sistema recopilará los errores encontrados exactos para ayudarlo a depurar el problema.
          </div>
        </div>
        <div class="item">
          <div class="titulo">
            Control de tiempos
          </div>
          <div class="explicacion">
            Vigilaremos el rendimiento de su sitio web en segundo plano. De este modo puedes detectar cuellos de botella debido a algún cambio realizado.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="div_secction" v-bind:class="{ encasillado: datos.encasillado }">
      <div class="div_cabecera">
        <div class="items ocultar_movil">
          <picture>
            <source srcset="../assets/imagenes/Work_6.webp" type="image/webp">
            <!-- Imagen fallback para navegadores antiguos -->
            <img style="width: 100%;" src="../assets/imagenes/Work_6.jpg" alt="Failder.com">
          </picture>

          <!--<img style="width: 100%;" src="../assets/imagenes/Work_6.webp">-->
        </div>
        <div class="items item1">
          <div class="item_texto">
            Deje que <span>Failder</span> compruebe la disponibilidad de los servidores  y concéntrese en su negocio
          </div>
          <!--<div class="item_texto_cambiante">
            <span id="typed"></span>
          </div>-->
          <div class="item_boton">
            <div>
              <a href="https://app.failder.com/registro/">
                <div class="btn_registro">
                  Regístrate gratis
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section_azul">
    <div class="div_secction" v-bind:class="{ encasillado: datos.encasillado }">
      <div>
        <h1>¿Para quién es Failder?</h1>
      </div>
      <!--<div>
        Vea cómo la completa plataforma de monitoreo Failder puede ayudarlo a delos objetivos de su sitio web.
      </div>-->
      <div class="contenido_que_hace">
        <div class="item">
          <div class="titulo">
            Especialistas en marketing
          </div>
          <div class="explicacion">
            Su sitio es su escaparate digital. Su material de marketing y sus campañas los han llevado a su sitio, pero si no está disponible, es lento o no funciona, podría dañar su marca y sus objetivos comerciales.
          </div>
        </div>
        <div class="item">
          <div class="titulo">
            Proveedores de alojamiento web
          </div>
          <div class="explicacion">
            Como proveedor de alojamiento web, es posible que tenga acuerdos sobre el rendimiento del sitio con sus clientes. Por ejemplo, los sitios web estarán el 99,9% del tiempo funcionando, o las páginas web tendrán menos de 3 segundos de tiempo de carga. Debido a estos acuerdos, siempre debe ser el primero en saber si ha ocurrido un incidente.
          </div>
        </div>
        <div class="item">
          <div class="titulo">
            Desarrolladores web
          </div>
          <div class="explicacion">
           Pruebe el rendimiento durante el desarrollo y solucione los problemas de producción: identifique los cuellos de botella, obtenga información útil y acelere su sitio web. Con la supervisión del tiempo de actividad, puede evitar tiempos de inactividad innecesarios y recibir una alerta tan pronto como su sitio no esté disponible.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="div_secction" v-bind:class="{ encasillado: datos.encasillado }">
      <div>
        <h1>Nuestros planes</h1>
      </div>
      <!--<div>
        Vea cómo la completa plataforma de monitoreo Failder puede ayudarlo a delos objetivos de su sitio web.
      </div>-->
      <div class="div_contenedor_fechas">
        <div class="btn_login" v-bind:class="{por_seleccionar: datos.tipo_licencia_mostrar != 1, btn_seleccionado:datos.tipo_licencia_mostrar == 1}" @click.native="seleccionar_tipo_de_licencias(1)">
          Mensual
        </div>
        <div class="btn_login" v-bind:class="{por_seleccionar: datos.tipo_licencia_mostrar != 2, btn_seleccionado:datos.tipo_licencia_mostrar == 2}" @click.native="seleccionar_tipo_de_licencias(2)">
          3 Meses
        </div>
        <div class="btn_login" v-bind:class="{por_seleccionar: datos.tipo_licencia_mostrar != 3, btn_seleccionado:datos.tipo_licencia_mostrar == 3}" @click.native="seleccionar_tipo_de_licencias(3)">
          6 Meses
        </div>
        <div class="btn_login" v-bind:class="{por_seleccionar: datos.tipo_licencia_mostrar != 4, btn_seleccionado:datos.tipo_licencia_mostrar == 4}" @click.native="seleccionar_tipo_de_licencias(4)">
          12 Meses
        </div>
      </div>
      <div class="contenedor_planes_de_precio">
        <item-precio-licencia v-for="item in datos.lista_planes_venta"
                              v-bind:id="item.id"
                              v-bind:descuento="item.descuento"
                              v-bind:meses="item.meses"
                              v-bind:nombre="item.nombre"
                              v-bind:precio="item.precio"
                              v-bind:cantidad_proyectos="item.cantidad_proyectos"
                              v-bind:dias_log="item.dias_log"
                              v-bind:cantidad_sms="item.cantidad_sms"
                              v-bind:tipo_check="item.tipo_check"
                              v-bind:tipo="item.tipo"
                              v-bind:cantidad_contactos="item.cantidad_contactos"
        >
        </item-precio-licencia>
      </div>
    </div>
  </section>
  <section class="section_azul">
    <div class="div_secction" v-bind:class="{ encasillado: datos.encasillado }">
      <div>
        <h1>
          Formulario de contacto
        </h1>
      </div>
      <div style="margin-bottom: 32px;">
        Para cualquier duda o para pedir información de nuestros planes personalizados, contacte con nosotros utilizando el siguiente formulario de contacto.
      </div>
      <div class="contenedor_form">
        <div class="form form1">
          <div class="form_item">
            <div class="titulo">
              Nombre
            </div>
            <div class="input">
              <input type="text" class="txt_nombre" v-model="datos.form.nombre">
            </div>
          </div>
          <div class="form_item">
            <div class="titulo">
              Número de Teléfono
            </div>
            <div class="input">
              <input type="tel" class="txt_tel" v-model="datos.form.tel">
            </div>
          </div>
          <div class="form_item">
            <div class="titulo">
              Email
            </div>
            <div class="input">
              <input type="email" class="txt_email" v-model="datos.form.email">
            </div>
          </div>

        </div>
        <div class="form form2">
          <div class="form_item">
            <div class="titulo">
              Consulta
            </div>
            <div class="input">
              <textarea type="text" class="txt_consulta" rows="7" v-model="datos.form.mensaje"></textarea>
            </div>
          </div>
          <div style="display:flex; flex-direction: row-reverse;">
            <div>
                <button class="btn_registro" @click.native="enviar_formulario()" >
                  Enviar
                </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <footer class="section_footer">
    <div class="div_secction" v-bind:class="{ encasillado: datos.encasillado }">
      <div>
        <img style="width: 140px; margin-bottom: 20px;" src="../assets/logos/LOGO_White.png" alt="Failder">
      </div>
      <div>
        Failder © Copyright {{ datos.anyo }}. Todos los derechos reservados.
      </div>
    </div>
  </footer>
  <div v-show="(datos.mostrar_banner_cookies)" class="banner_aviso_legal" v-bind:class="{ banner_aviso_legal_fijo: datos.aviso_lega_fijo }">
    <div class="texto_cookies" style="">
      Utilizamos cookies propias y de terceros para obtener datos estadísticos de la navegación de nuestros usuarios y mejorar nuestros servicios.
      <br>
      Puede leer más sobre el uso de cookies en nuestra <a style="color: var(--primary-color-1);" href="#">política de privacidad</a>.
    </div>
    <div>
      <div class="btn_cookies">
        Aceptar
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import HeaderNavigation from '../components/HeaderNavigation'
import ItemPrecioLicencia from "@/components/ItemPrecioLicencia";
import Typed from '../js/typed';
import Swal from 'sweetalert2';
/*window.onscroll = function (e) {
  const top = document.documentElement.scrollTop
  if (top > )
}*/


export default {
  name: 'Home',
  components: {
    HeaderNavigation,
    ItemPrecioLicencia
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    datos.scroll_position = 0;
    datos.lista_planes_venta = [];
    datos.tipo_licencia_mostrar = 1;
    datos.anyo = 2022;
    datos.aviso_lega_fijo = true;
    datos.mostrar_banner_cookies = false;

    datos.form = {};
    datos.form.nombre = '';
    datos.form.email = '';
    datos.form.tel = '';
    datos.form.mensaje = '';


    onMounted(function (){
      //console.log('Comprobar URL', route.fullPath)
      /*if (datos.modo_oscuro){
        document.body.classList.add("fondo_dark_theme");
        document.getElementById('app').classList.add("texto_dark_theme");
      } else {
        document.body.classList.remove("fondo_dark_theme")
        document.getElementById('app').classList.remove("texto_dark_theme");
      }*/
          if (localStorage.aviso_cookies == undefined) {
            datos.mostrar_banner_cookies = false;
          }

      datos.typed = new Typed("#typed", {
        strings: ['Monitoreo de sitios web y APIs', 'Alertas instantáneas de errores', 'Registro de tiempos de respuesta'],
        typeSpeed: 25,
        backSpeed: 10,
        backDelay: 1500,
        startDelay: 1000,
        loop: true,
        //onBegin: function(self) { this.prettyLog('onBegin ' + self) },
        onComplete: (self) => console.log('onCmplete ' + self),
        /*preStringTyped: function(pos, self) { this.prettyLog('preStringTyped ' + pos + ' ' + self); },
        onStringTyped: function(pos, self) { this.prettyLog('onStringTyped ' + pos + ' ' + self) },
        onLastStringBackspaced: function(self) { this.prettyLog('onLastStringBackspaced ' + self) },
        onTypingPaused: function(pos, self) { this.prettyLog('onTypingPaused ' + pos + ' ' + self) },
        onTypingResumed: function(pos, self) { this.prettyLog('onTypingResumed ' + pos + ' ' + self) },
        onReset: function(self) { this.prettyLog('onReset ' + self) },
        onStop: function(pos, self) { this.prettyLog('onStop ' + pos + ' ' + self) },
        onStart: function(pos, self) { this.prettyLog('onStart ' + pos + ' ' + self) },*/
        onDestroy: () => console.log('onDestroy ' + self)
      });




    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    mostrar_mensaje_error(texto_error){
      Swal.fire({
        icon: 'error',
        title: '¡Oops!',
        text: texto_error,
      })
    },
    async enviar_formulario() {






      if (this.datos.form.nombre.trim() == ''){
        this.mostrar_mensaje_error('Parece que falta rellenar el nombre.')
        return false;
      }
      if (this.datos.form.email.trim() == ''){
        this.mostrar_mensaje_error('Parece que falta rellenar el correo electrónico.')
        return false;
      }
      if (this.datos.form.mensaje.trim() == ''){
        this.mostrar_mensaje_error('Parece que falta rellenar el mensaje.')
        return false;
      }

      let datos_enviar = {
        "nombre": this.datos.form.nombre.trim(),
        "email": this.datos.form.email.trim(),
        "tel": this.datos.form.tel.trim(),
        "mensaje":this.datos.form.mensaje.trim()
      }
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/web/contacto`, requestOptions);
      if (response.status != 200){
        this.datos.mensaje_error = 'ERROR';
        console.log('ERROR', response.status);
        console.log('data', response);
        this.datos.mostrar_btn_atras = true;
        this.mostrar_mensaje_error('Se ha producido un error.\nInténtalo de nuevo.');
        return false;
      }
      const datos = await response.json();
      console.log('datos web', datos);
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        //this.datos.mensaje_error = 'Error en el servicio. Inténtalo de nuevo';
        //this.datos.mostrar_btn_atras = true;
        this.mostrar_mensaje_error('Se ha producido un error.\nInténtalo de nuevo.');
      } else {
       /* this.datos.mostrar_btn_atras = false;
        this.datos.mostrar_btn_finalizar = true;
        this.datos.mostrar_cargador = false;
        this.datos.mensaje_correcto = '¡Proceso finalizado!'*/
        this.datos.form.nombre = '';
        this.datos.form.email = '';
        this.datos.form.tel = '';
        this.datos.form.mensaje = '';
        //TODO: Requiere un mensaje de confirmacion
        Swal.fire({
          icon: 'success',
          title: '¡Mensaje enviado correctamente!',
          /*text: 'En breve te responderemos',*/
        })
      }
    },

    scrollTop: function () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20)
    },
    scrollListener: function (e) {
      this.visible = window.scrollY > 150
    },


    handleScroll (event) {
      // Any code to be executed when the window is scrolled
      /*console.log('hola');
      const top = document.documentElement.scrollTop
      console.log(top);*/
    },
    prettyLog(text){
      console.log('prettyLog', text);
    },
    seleccionar_tipo_de_licencias(tipo_licencia){
      this.datos.tipo_licencia_mostrar = tipo_licencia
    },
    async get_lista_planes_venta() {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      const response = await fetch(`${this.datos.url_host}/web/licencias/`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        return false;
      }

      //Respuesta 200
      const respuestaOK = await response.json();
      console.log(respuestaOK);
      if(respuestaOK.code != '00'){
        console.log('Respuesta error', respuestaOK.code);
        console.log('Respuesta', respuestaOK);
        return false;
      }


      let datos_recibidos = respuestaOK.data;
      console.log('Respuesta SHOP', datos_recibidos);
      this.datos.lista_planes_venta = [];
      //this.datos.lista_planes_venta =  datos_recibidos;
      this.datos.mostrar_cargando = false;

      this.datos.mostrar_boton_licencias_personalizadas = false;
      const cantidad_licencias = datos_recibidos.length;
      for (let a = 0; a < cantidad_licencias; a++){
        if (this.datos.mostrar_boton_licencias_personalizadas) break;
        let item = datos_recibidos[a];
        if(item.tipo == 0) this.datos.mostrar_boton_licencias_personalizadas = true;
        item.precio = `${item.precio}`;
        item.precio = item.precio.replace(".", ",");
        this.datos.lista_planes_venta.push(item);
      }



    },
    async get_anyo() {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      const response = await fetch(`${this.datos.url_host}/web/anyo/`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        return false;
      }

      //Respuesta 200
      const respuestaOK = await response.json();
      console.log(respuestaOK);
      if(respuestaOK.code != '00'){
        console.log('Respuesta error', respuestaOK.code);
        console.log('Respuesta', respuestaOK);
        return false;
      }


      let datos_recibidos = respuestaOK.data;
      this.datos.anyo = datos_recibidos;
    },
  },
  mounted() {
    this.get_anyo();
    this.get_lista_planes_venta();
  }
}
</script>

<style lang="scss" scoped>
.div_cabecera{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: space-around;
  align-items: center;
}
.div_cabecera .items{
  width: 50%;
  padding-left: 30px;
  padding-right: 30px;
}

.div_cabecera .items .item_texto{
  margin-bottom: 32px;
  font-size: 36px;
}

.div_cabecera .item1{

}
.div_cabecera .items .item_texto_cambiante{
  font-size: 20px;
  margin-bottom: 32px;
}
.div_cabecera .items .item_boton{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.contenido_que_hace{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 42px;
}
.contenido_que_hace .item{
  width: 30%;
  padding-right: 8px;
  padding-left: 8px;
}
.contenido_que_hace .item .titulo{
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}
.contenedor_planes_de_precio{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
}

.contenedor_form{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: flex-start;
}
.contenedor_form .form{
  width: 50%;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 10px;
}
.contenedor_form .form_item .titulo{
  text-align: left;
}

.contenedor_form .form_item{
  padding-bottom: 6px;
}
.contenido_que_hace .item .explicacion {
  text-align: justify;
}
@media screen and (max-width: 991px){
  .contenedor_form .form{
    width: 100%;
  }
  .div_cabecera .items{
    width: 100%;
  }
  .contenido_que_hace .item{
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
    margin-bottom: 26px;
  }

  .div_cabecera .items .item_texto{
    margin-bottom: 28px;
    font-size: 24px;
  }

  .div_cabecera .items .item_texto_cambiante{
    font-size: 18px;
    margin-bottom: 32px;
  }
  h1 {
    font-size: 1.4em;
  }
  .contenido_que_hace .item .titulo {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .contenido_que_hace .item .explicacion {
    font-size: 14px;
  }
  footer{
    font-size: 14px;
  }

}
</style>
